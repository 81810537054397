import React, {useState, useEffect} from 'react';
import { default as NumberFormat } from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux'
import { calculateMortgage } from '../slices/mortgage';
import homeIcon from  "../assets/images/home.svg";
import infoIcon from  "../assets/images/info.svg";


const  Form = () => {
  const dispatch = useDispatch();
  const [income, setIncome] = useState <number | null>(null);
  const [bonus, setBonus] = useState <number | null> (null);
  const [hasIncome, setHasIncome] = useState(false);
  const [hasBonus, setHasBonus] = useState(false);
  const [mortgageType, setMortgageType] = useState <string>('single');


  const handleInputValidation = (values : any) => {
    if(!values.value.length){
      return true;
    }

    return values.value.length < 9;
  }

  const handleMortgageType = (e : React.ChangeEvent<HTMLInputElement> ) => {
    setMortgageType(e.target.value);
  };

  const handleIncomeChange = (val : number | null = null) => {
    if(!val){
      setIncome(null);
    }

    if(val && val >= 0 && val.toString().length < 9 ){
      setIncome(val);
    }

  };

  const handleBonusChange = (val : number | null = null) => {
    if(!val){
      setBonus(null);
    }

    if(val && val >= 0 && val.toString().length < 9){
      setBonus(val);
    }
  }

  useEffect(()=>{
      dispatch(calculateMortgage(income, bonus));
  },[dispatch, income, bonus]);

  return (
    <>
    <div className="mac-about-your-outer">
        <div className="mac-about-your">
            <div className="mac-abt-ur-head">
                <img src={homeIcon} className="mac-home-icon" />
            </div>
            <h2>About your mortgage </h2>
        </div>
        <div className="mac-mortagage-form-section">
            <h3>Are you applying for a single or joint mortgage?</h3>
            <form className="mac-form-inner">
                <div className="mac-form-radio-btn">
                <label className="mac-radio" htmlFor="single">
                    <input type="radio" name="mortgagetype" id="single" onChange={handleMortgageType} value="single" checked={mortgageType == 'single'}/>Single
                    <span className="checkround"></span>
                  </label>
                  <label className="mac-radio" htmlFor="joint">
                    <input type="radio" name="mortgagetype" id="joint" onChange={handleMortgageType} value="joint"  checked={mortgageType == 'joint'}/>Joint
                    <span className="checkround"></span>
                  </label>
                  </div>
                  <div className="mac-frm-input">
                          <div className="mac-input-box">
                            <label htmlFor="yearly-income">What’s your yearly income? </label>
                            <span>
                            <NumberFormat id="yearly-income" name="year" thousandSeparator={true} prefix={"£ "} value={income} isAllowed={handleInputValidation} decimalScale={2} allowNegative={false} onValueChange={(values) => {handleIncomeChange(values.floatValue);}} placeholder={"£ "} />
                            <img src={infoIcon} className="mac-info-icon" data-tip="If joint, please provide the total amount you both earn" />
                            <ReactTooltip place="top" type="info" effect="solid" />
                            </span>
                          </div>
                          <div className="mac-input-box">
                              <label htmlFor="regular-bonus">Do you receive a regular bonus? If so, how much is this per year?   </label>
                              <NumberFormat id="regular-bonus" name="year" thousandSeparator={true} prefix={"£ "} value={bonus} isAllowed={handleInputValidation} decimalScale={2} allowNegative={false} onValueChange={(values) => {handleBonusChange(values.floatValue)}} placeholder={"£ "} />
                        </div>
                  </div>
            </form>
        </div>
        </div>
    </>
  );
}

export default Form;
