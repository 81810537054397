import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  loading: false,
  hasErrors: false,
  mortgage: {
    affordable: 0,
    middle: 0,
    unaffordable : 0
  },
}


const mortgageSlice = createSlice({
  name: 'mortgage',
  initialState,
  reducers: {
    calculateMortgageRequest: (state) => {
      state.loading = true
    },
    calculateMortgageSuccess: (state, { payload }) => {
      state.mortgage = payload
      state.loading = false
      state.hasErrors = false
    },
    calculateMortgageFailure: (state) => {
      state.loading = false
      state.hasErrors = true
    },
  },
});


/*** Three actions generated from the slice ***/
export const { calculateMortgageRequest, calculateMortgageSuccess, calculateMortgageFailure } = mortgageSlice.actions

/*** A Selector ***/
export const mortgageSelector = (state) => state.mortgage

/*** The reducer ***/
export default mortgageSlice.reducer

/*** Asynchronous thunk action ***/
export function calculateMortgage(income, bonus) {
  return async (dispatch) => {
    dispatch(calculateMortgageRequest())

    try {
      let totalIncome  =  Number(income) + Number(bonus);
      let data =  await {
        affordable: totalIncome ? Number((totalIncome * 3.5).toFixed(2)) : 0,
        middle: totalIncome ? Number((totalIncome * 4.25).toFixed(2)) : 0,
        unaffordable : totalIncome ? Number((totalIncome * 5).toFixed(2)) : 0
      };

      dispatch(calculateMortgageSuccess(data))
    } catch (error) {
      dispatch(calculateMortgageFailure())
    }
  }
}
