import {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { default as NumberFormat } from 'react-number-format';
import { mortgageSelector } from '../slices/mortgage';

const Result = () => {
  const [smallText, setSmallText] = useState <boolean>(false);
  const { mortgage } = useSelector(mortgageSelector);
  const {affordable, middle, unaffordable} = mortgage;

  useEffect(( ) => {
    let enableSmallText = (affordable.toString().length > 6 || middle.toString().length > 6 || unaffordable.toString().length > 6);
    setSmallText(enableSmallText);
  }, [affordable, middle, unaffordable]);

  return (
    <>
    <div className={smallText ? "mac-calc-main mac-small-text" : "mac-calc-main"}>
    <h2 className="box-heading">Based on your yearly income, you may be able to borrow:</h2>
    <div className="mac-income-wrapper">
        <div className="mac-yearbox mac-purpl-box">
            <h3><NumberFormat displayType={'text'} thousandSeparator={true} prefix={"£"} value={affordable} /></h3>
            <div className="mac-bar-content-wrapper">
                <div className="mac-year-bar"></div>
                <div className="mac-year-content">
                    <p>Most lenders will let you borrow 3.5 times your annual salary so, as long as you have a standard 10% deposit, you should be able to borrow this much.</p>
                </div>
            </div>
        </div>
        <div className="mac-yearbox mac-grey-box">
            <h3><NumberFormat displayType={'text'} thousandSeparator={true} prefix={"£"} value={middle} /></h3>
            <div className="mac-bar-content-wrapper">
                <div className="mac-year-bar"></div>
                <div className="mac-year-content">
                    <p>Depending on your personal circumstances, some lenders may let you borrow 4 – 4.5 times your salary.</p>
                </div>
            </div>
        </div>
        <div className="mac-yearbox mac-blue-box">
            <h3><NumberFormat displayType={'text'} thousandSeparator={true} prefix={"£"} value={unaffordable} /></h3>
            <div className="mac-bar-content-wrapper">
                <div className="mac-year-bar"></div>
                <div className="mac-year-content">
                     <p>Lenders usually cap the amount they lend at 5 times your salary, so it’s unlikely you’ll be able to borrow more than this.</p>
                </div>
            </div>
        </div>
    </div>
    <div className="mac-calc-footer">
        <a href="https://www.fluentmoney.co.uk/mortgages/mortgage-quote/" className="btn" target="_top">Get a Quote</a>
        <p>This calculator is an estimation of how much you could borrow. If you’re ready to take out a mortgage, speak to a Fluent adviser to see what options are available.</p>
    </div>
</div>
    </>
  );
}

export default Result;
