import Form from '../components/Form';
import Result from '../components/Result';
import separatorIcon from '../assets/images/separator.svg';

const Main = () => {
  return (
    <>
    <div className="mac-top-section">

    </div>
	<div className="container">
		<div className="mac-inner-content">
		  <div className="row">
			  <div className="col-md-6">
				  <Form/>
			  </div>
			  <div className="col-md-6 mac-income-part">
				  <Result/>
			  </div>
		  </div>
		</div>
	</div>
    </>
  );
}

export default Main;
